import React from "react";
import { PricingBreakDown } from "@api/graphql/generated/generated-types";

type Props = {
  pricingBreakDown: PricingBreakDown;
  total: number;
};

function PricingBreakDownTable({ pricingBreakDown, total }: Props) {
  return (
    <div className="px-1">
      <table className="w-full border-collapse">
        <tbody>
          {pricingBreakDown.items.map((item, index) => (
            <tr key={index}>
              <td className="py-2">
                <div className="flex flex-col">
                  <p>{item.name}</p>
                </div>
                {item.description && (
                  <div className="text-slate-400">
                    <p>{item.description}</p>
                  </div>
                )}
              </td>
              <td className="py-2">${item.amount}</td>
            </tr>
          ))}
          <tr key={"total"}>
            <td className="py-4">
              <div className="font-semibold">Total</div>
            </td>
            <td className="font-semibold py-4">${total}</td>
          </tr>
        </tbody>
        <p className=" text-xs text-slate-400 italic">
          The order price has been finalized.
        </p>
      </table>
    </div>
  );
}

export default PricingBreakDownTable;
