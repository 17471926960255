import React from "react";
import { Label, Loading, Tooltip } from "@src/common/components";
import {
  ArrowUturnLeftIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import BasePriceOverrideForm from "./BasePriceOverrideForm";
import {
  Maybe,
  OrderPrice,
  PricingBreakDownItem,
  useOverrideAdjustmentAmountMutation,
} from "@api/graphql/generated/generated-types";

type Props = {
  basePriceOverrideMode: boolean;
  setBasePriceOverrideMode: (value: boolean) => void;
  PricingBreakDownItem: PricingBreakDownItem;
  removedItems: Record<string, boolean>;
  basePriceOverrideValue: number;
  setBasePriceOverrideValue: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  setRemovedItems: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  undoBasePriceOverride: () => void;
  orderPrice: OrderPrice | undefined;
  setEditItems: React.Dispatch<
    React.SetStateAction<Record<number, PricingBreakDownItem>>
  >;
  onModifyOrderPricing: (() => void) | undefined;
};

function PricingBreakDownItemComponent({
  basePriceOverrideMode,
  setBasePriceOverrideMode,
  PricingBreakDownItem: item,
  removedItems,
  basePriceOverrideValue,
  setBasePriceOverrideValue,
  index: i,
  setRemovedItems,
  undoBasePriceOverride,
  orderPrice,
  setEditItems,
  onModifyOrderPricing,
}: Props) {
  const [
    overrideAdjustmentAmount,
    {
      data: overrideAdjustmentAmountData,
      loading: overrideAdjustmentAmountLoading,
      error: overrideAdjustmentAmountError,
    },
  ] = useOverrideAdjustmentAmountMutation();

  const handleRemoveItem = (adjustmentId: Maybe<string> | undefined) => {
    if (adjustmentId) {
      setRemovedItems((prev) => ({ ...prev, [adjustmentId]: true }));
    }
  };

  const handleUndoRemove = (adjustmentId: Maybe<string> | undefined) => {
    if (adjustmentId) {
      setRemovedItems((prev) => ({ ...prev, [adjustmentId]: false }));
    }
  };

  if (!orderPrice) {
    return null;
  }

  return (
    <div
      className={classNames({
        "mb-2 flex justify-between hover:bg-slate-50 rounded-sm": true,
        "line-through": item.adjustmentId && removedItems[item.adjustmentId],
      })}
    >
      {basePriceOverrideMode && !item.adjustmentId && i === 0 ? (
        <BasePriceOverrideForm
          basePriceOverrideValue={basePriceOverrideValue}
          setBasePriceOverrideValue={setBasePriceOverrideValue}
          setBasePriceOverrideMode={setBasePriceOverrideMode}
        />
      ) : basePriceOverrideMode && !item.adjustmentId && i > 0 ? null : (
        <div
          className={classNames({
            "opacity-60": item.adjustmentId && removedItems[item.adjustmentId],
          })}
        >
          <Label>{item.amount}</Label>
          <p className="text-xs -mt-1">{item.name}</p>
        </div>
      )}
      {item.adjustmentId ? (
        <div className="flex flex-col justify-center">
          {removedItems[item.adjustmentId] ? (
            <ArrowUturnLeftIcon
              className="h-5 w-5 inline-block mr-2 cursor-pointer hover:text-slate-900 rounded-full p-0.5"
              onClick={() => handleUndoRemove(item.adjustmentId)}
            />
          ) : (
            <div className="flex">
              {item.isAdjustmentOverride ? (
                <Tooltip content="Undo Adjustment Override">
                  <Loading loading={overrideAdjustmentAmountLoading}>
                    <ArrowUturnLeftIcon
                      onClick={() => {
                        if (!item.adjustmentId) {
                          return;
                        }
                        overrideAdjustmentAmount({
                          variables: {
                            adjustmentId: item.adjustmentId,
                            amountOverride: undefined,
                          },
                        }).then(() => {
                          if (onModifyOrderPricing) {
                            onModifyOrderPricing();
                          }
                        });
                      }}
                      className="h-5 w-5 inline-block mr-2 cursor-pointer hover:text-slate-900 rounded-full p-0.5"
                    />
                  </Loading>
                </Tooltip>
              ) : (
                <PencilIcon
                  onClick={() => {
                    const adjustmentId = item.adjustmentId || "";
                    setEditItems((prev) => ({
                      ...prev,
                      [adjustmentId]: { ...item },
                    }));
                  }}
                  className="h-5 w-5 inline-block mr-2 cursor-pointer hover:text-slate-900 p-0.5"
                />
              )}

              <TrashIcon
                className="h-5 w-5 inline-block mr-2 cursor-pointer hover:text-slate-900 rounded-full p-0.5"
                onClick={() => handleRemoveItem(item.adjustmentId)}
              />
            </div>
          )}
        </div>
      ) : orderPrice.isBasePriceOverride ? (
        <div>
          <ArrowUturnLeftIcon
            className="h-5 w-5 inline-block mr-2 cursor-pointer hover:text-slate-900 rounded-full p-0.5"
            onClick={() => undoBasePriceOverride()}
          />
        </div>
      ) : (
        i === 0 &&
        !basePriceOverrideMode && (
          <div
            className="flex flex-col justify-center"
            onClick={() => setBasePriceOverrideMode(true)}
          >
            <PencilIcon className="h-5 w-5 inline-block mr-2 cursor-pointer p-0.5 hover:text-slate-900" />
          </div>
        )
      )}
    </div>
  );
}

export default PricingBreakDownItemComponent;
