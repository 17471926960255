import React, { useEffect, useState } from "react";
import { PricingBreakDownItem } from "@api/graphql/generated/generated-types";
import DollarInput from "@src/common/components/Input/DollarInput";
import { Loading } from "@src/common/components/Loading";
import CourieButton from "@src/common/components/Button";

type Props = {
  setEditItems: React.Dispatch<
    React.SetStateAction<Record<number, PricingBreakDownItem>>
  >;
  pricingBreakDownItem: PricingBreakDownItem;
};

function PricingBreakDownItemEdit({
  setEditItems,
  pricingBreakDownItem,
}: Props) {
  const [inputValue, setInputValue] = useState<number>(
    Number(pricingBreakDownItem.amount)
  );

  useEffect(() => {
    const adjustmentId = pricingBreakDownItem.adjustmentId || "";
    setEditItems((prev) => ({
      ...prev,
      [adjustmentId]: {
        amount: inputValue,
      },
    }));
  }, [inputValue]);

  const handleCancelClick = (pricingBreakDownItem: PricingBreakDownItem) => {
    const adjustmentId = pricingBreakDownItem.adjustmentId as any;
    if (adjustmentId) {
      setEditItems((prev) => {
        const { [adjustmentId]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  return (
    <div className="flex gap-2">
      <div className="w-3/4 flex flex-col py-0.5 gap-1">
        <DollarInput
          size="mini"
          dollar={inputValue}
          setDollar={(value) => setInputValue(value)}
          onChange={(event) => {
            const value = event.target.value;
            setInputValue(Number(value));
          }}
        />
        <p className="text-xs -mt-1">{pricingBreakDownItem.name}</p>
      </div>
      <Loading loading={false} className="w-1/4 flex gap-2 justify-end py-1">
        <CourieButton
          color="gray"
          size="xs"
          onClick={() => {
            handleCancelClick(pricingBreakDownItem);
          }}
        >
          Cancel
        </CourieButton>
      </Loading>
    </div>
  );
}

export default PricingBreakDownItemEdit;
