import {
  GetDispatchPreviewsQuery,
  useGetDispatchPreviewsLazyQuery,
} from "@api/graphql/generated/generated-types";
import { DispatchCandidateView } from "@src/common/components/Widgets/DispatchCandidatesWidget/DispatchCandidatesWidget";
import React, { createContext, useContext, useState } from "react";

// Define the context interface
interface DispatchPreviewContextProps {
  dispatchPreviewLoading: boolean;
  dispatchPreviewError: any;
  dispatchPreviewApiData: GetDispatchPreviewsQuery | undefined;
  getDispatchPreviews: any;
  dispatchPreviewStartPolling: (pollInterval: number) => void;
  dispatchPreviewStopPolling: () => void;
  dispatchCandidatesWidgetSelectedItem: DispatchCandidateView | undefined;
  setDispatchCandidatesWidgetSelectedItem: (
    item: DispatchCandidateView | undefined
  ) => void;
}

// the context with a default value
const DispatchPreviewContext =
  createContext<DispatchPreviewContextProps | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

// the context provider
const DispatchPreviewProvider = ({ children }: Props) => {
  const [
    dispatchCandidatesWidgetSelectedItem,
    setDispatchCandidatesWidgetSelectedItem,
  ] = useState<DispatchCandidateView | undefined>(undefined);
  const [
    getDispatchPreviews,
    {
      data: dispatchPreviewApiData,
      loading: dispatchPreviewLoading,
      error: dispatchPreviewError,
      startPolling: dispatchPreviewStartPolling,
      stopPolling: dispatchPreviewStopPolling,
    },
  ] = useGetDispatchPreviewsLazyQuery();

  return (
    <DispatchPreviewContext.Provider
      value={{
        dispatchPreviewLoading,
        dispatchPreviewError,
        dispatchPreviewApiData,
        getDispatchPreviews,
        dispatchPreviewStartPolling,
        dispatchPreviewStopPolling,
        dispatchCandidatesWidgetSelectedItem,
        setDispatchCandidatesWidgetSelectedItem,
      }}
    >
      {children}
    </DispatchPreviewContext.Provider>
  );
};

// the custom hook
const useDispatchPreviewContext = () => {
  const context = useContext(DispatchPreviewContext);
  if (context === undefined) {
    throw new Error(
      "useDispatchPreviewContext must be used within a DispatchPreviewProvider"
    );
  }
  return context;
};

export { DispatchPreviewProvider, useDispatchPreviewContext };
