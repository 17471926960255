import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { ShipmentRow, ShipmentsPrimaryFilterType } from "../types";
import { useShipmentsCoreDataContext } from "./ShipmentsCoreData";
import {
  GetDispatchPreviewsQueryVariables,
  Shipment,
  ShipmentEdge,
  ShipmentStatus,
  useRecordOrderClickMutation,
} from "@api/graphql/generated/generated-types";
import { makeShipmentEdgesToShipmentRow } from "@src/shipments/utils/shipmentRowFormatter";
import { useDispatchPreviewContext } from "./DispatchPreviewContext";
import { useShipmentFiltersContext } from "./ShipmentFiltersContext";
import { AuthContext } from "@src/auth/components/AuthProvider";

// Define the context interface
interface ShipmentSelectionContextProps {
  selectedRow: ShipmentRow | undefined;
  setSelectedRow: (row: ShipmentRow | undefined) => void;
  handleSelectRow: (row: ShipmentRow, callback?: () => void) => void;
  selectedShipment: Shipment | undefined;
  setSelectedShipment: (selectedShipment: Shipment | undefined) => void;
  shipmentSelectedForAction: Shipment | undefined;
  setShipmentSelectedForAction: (shipment: Shipment | undefined) => void;
  checkedShipments: string[];
  setCheckedShipments: (checkedShipments: string[]) => void;
  isAllUnassignedChecked: boolean;
  setIsAllUnassignedChecked: React.Dispatch<React.SetStateAction<boolean>>;
  orderIdsClicked: string[];
}

// the context with a default value
const ShipmentSelectionContext =
  createContext<ShipmentSelectionContextProps | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

// the context provider
const ShipmentSelectionProvider = ({ children }: Props) => {
  const { courieUser, isEmployee, customClaims, employeeId } =
    useContext(AuthContext);
  const [recordOrderClickMutation] = useRecordOrderClickMutation();
  // states
  const [selectedRow, setSelectedRow] =
    useState<ShipmentRow | undefined>(undefined);
  const [selectedShipment, setSelectedShipment] =
    useState<Shipment | undefined>(undefined);
  const [shipmentSelectedForAction, setShipmentSelectedForAction] =
    useState<Shipment | undefined>();
  const [checkedShipments, setCheckedShipments] = useState<string[]>([]);
  const [isAllUnassignedChecked, setIsAllUnassignedChecked] = useState(false);
  const [orderIdsClicked, setOrderIdsClicked] = useState<string[]>([]);

  // contexts
  const { shipmentsApiResponse, shipmentsApiLoading } =
    useShipmentsCoreDataContext();
  const {
    getDispatchPreviews,
    dispatchPreviewStartPolling,
    dispatchPreviewStopPolling,
  } = useDispatchPreviewContext();

  const { minDate, maxDate, selectedPrimaryFilter } =
    useShipmentFiltersContext();

  // Add debounce timer ref
  const debounceTimerRef = useRef<NodeJS.Timeout>();

  // Debounced mutation function
  const debouncedRecordClick = useCallback(
    (orderId: string, clickedBy: string) => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }

      debounceTimerRef.current = setTimeout(() => {
        recordOrderClickMutation({
          variables: {
            input: {
              orderId,
              clickedBy: clickedBy,
            },
          },
        }).then(() => {
          setOrderIdsClicked((prev) => [...prev, orderId]);
        });
      }, 500); // 500ms debounce delay
    },
    [recordOrderClickMutation]
  );

  useEffect(() => {
    const { shipments } = shipmentsApiResponse ?? {};

    if (shipmentsApiLoading || shipments === undefined || shipments === null) {
      return;
    }

    const shipmentEdges = shipments.edges ?? [];
    const hasShipments = shipmentEdges.length > 0;

    if (!hasShipments) {
      setSelectedRow(undefined);
      setSelectedShipment(undefined);
      return;
    }

    const shipmentRowFoundInApiResponse = selectedRow
      ? shipmentEdges.some(({ node }: any) => node?.id === selectedRow.id)
      : false;

    if (!shipmentRowFoundInApiResponse && hasShipments) {
      const shipmentRows: ShipmentRow[] = makeShipmentEdgesToShipmentRow(
        shipmentEdges as ShipmentEdge[]
      );
      handleSelectRow(shipmentRows[0]);
    }
  }, [selectedPrimaryFilter, shipmentsApiResponse, shipmentsApiLoading]);

  // effects

  useEffect(() => {
    if (checkedShipments.length > 0 && selectedRow) {
      setSelectedRow(undefined);
    }
  }, [checkedShipments]);

  useEffect(() => {
    if (checkedShipments.length > 0) {
      setCheckedShipments([]);
    }
    if (isAllUnassignedChecked) {
      setIsAllUnassignedChecked(false);
    }
  }, [selectedPrimaryFilter]);

  useEffect(() => {
    setSelectedRow(undefined);
    setCheckedShipments([]);
  }, [minDate, maxDate]);

  useEffect(() => {
    if (!selectedShipment) {
      return;
    }
    const selectedShipmentEdge = shipmentsApiResponse?.shipments?.edges?.find(
      (shipment: any) => {
        if (shipment.node.id === selectedShipment.id) {
          return shipment;
        }
      }
    );
    const updatedRow: ShipmentRow = makeShipmentEdgesToShipmentRow([
      selectedShipmentEdge as ShipmentEdge,
    ])[0];
    setSelectedRow(updatedRow);
  }, [selectedShipment]);

  useEffect(() => {
    if (shipmentsApiResponse && shipmentsApiResponse.shipments?.edges) {
      const shipmentRows: ShipmentRow[] = makeShipmentEdgesToShipmentRow(
        shipmentsApiResponse.shipments.edges as ShipmentEdge[]
      );
      if (selectedRow === undefined) {
        handleSelectRow(shipmentRows[0]);
      } else {
        const selectedRowId = selectedRow.id;
        const selectedRowExists = shipmentRows.find((row) => {
          if (row) {
            return row.id === selectedRowId;
          }
        });
        if (selectedRowExists) {
          handleSelectRow(selectedRowExists);
        }
      }
    }
  }, [shipmentsApiResponse]);

  useEffect(() => {
    if (!selectedRow || !shipmentsApiResponse) {
      return;
    }
    if (selectedRow) {
      const shipmentEdges = shipmentsApiResponse?.shipments?.edges;
      const selectedShipment = shipmentEdges?.find((shipment: any) => {
        if (shipment.node.id === selectedRow.id) {
          return shipment;
        }
      });
      if (selectedShipment?.node) {
        setSelectedShipment(selectedShipment?.node as Shipment);
      }
      if (selectedShipment?.node?.status === ShipmentStatus.Created) {
        dispatchPreviewStartPolling(30 * 1000); // every 30 seconds
      } else {
        dispatchPreviewStopPolling();
      }
    } else {
      dispatchPreviewStopPolling();
    }

    return () => {
      setSelectedShipment(undefined);
    };
  }, [selectedRow, shipmentsApiResponse]);

  // functions
  function handleSelectRow(row: ShipmentRow, callback?: () => void) {
    if (
      row?.shipment?.order.id &&
      row?.id !== selectedRow?.id &&
      !row?.shipment?.order.userHasClicked
    ) {
      const clickedBy = isEmployee
        ? employeeId
        : courieUser?.id || customClaims?.courieUserId || undefined;
      if (!clickedBy) {
        return;
      }
      debouncedRecordClick(row.shipment.order.id, clickedBy);
    }
    if (row?.id === selectedRow?.id || checkedShipments.length > 0) {
      if (callback) {
        callback();
      }
      return;
    }
    if (row?.shipmentStatus === ShipmentStatus.Created) {
      getDispatchPreviews({
        variables: {
          shipmentId: row?.id,
          taskStatusFilter: ["CREATED", "STARTED"],
          shipmentDate: row?.shipmentDate,
        } as GetDispatchPreviewsQueryVariables,
        fetchPolicy: "cache-and-network",
      });
    }
    setSelectedRow(row);
    if (callback) {
      callback();
    }
  }

  return (
    <ShipmentSelectionContext.Provider
      value={{
        selectedRow,
        setSelectedRow,
        handleSelectRow,
        selectedShipment,
        setSelectedShipment,
        shipmentSelectedForAction,
        setShipmentSelectedForAction,
        checkedShipments,
        setCheckedShipments,
        isAllUnassignedChecked,
        setIsAllUnassignedChecked,
        orderIdsClicked,
      }}
    >
      {children}
    </ShipmentSelectionContext.Provider>
  );
};

// the custom hook
const useShipmentSelectionContext = () => {
  const context = useContext(ShipmentSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useShipmentSelectionContext must be used within a ShipmentSelectionProvider"
    );
  }
  return context;
};

export { ShipmentSelectionProvider, useShipmentSelectionContext };
