import { Input as BaseInput, InputProps } from "baseui/input";

export interface PercentInputProps extends InputProps {
  percent: number;
  setPercent: (percent: number) => void;
}

const PercentInput: React.FC<PercentInputProps> = ({
  percent,
  setPercent,
  onChange,
  ...props
}) => {
  return (
    <BaseInput
      startEnhancer="%"
      min={0}
      max={100}
      value={`${percent.toFixed(2)}`}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        let num: number = 0.0;
        if (event.target.value) {
          const numValue = event.target.value.replace(/[^\d-]|(?!^)-/g, "");
          num = numValue === "" ? 0.0 : Number(numValue);
        }
        setPercent(num / 100);
      }}
      {...props}
      overrides={{
        Input: {
          style: {
            backgroundColor: "rgb(249 250 251)",
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export default PercentInput;
