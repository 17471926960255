import React, { useContext, useEffect, useState } from "react";
import {
  OrderPayBreakDownQuery,
  Shipment,
  useUpdateDriverPayOverrideMutation,
} from "@api/graphql/generated/generated-types";
import { Button, Label } from "@src/common/components";
import { Loading } from "@src/common/components/Loading";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import DollarInput from "@src/common/components/Input/DollarInput";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "@src/auth/components/AuthProvider";

type Props = {
  selectedShipment: false | Shipment | null | undefined;
  fetchOrderPayBreakDown: any;
  fetchOrderPayBreakDownLoading: boolean;
  fetchOrderPayBreakDownData: OrderPayBreakDownQuery | undefined;
  setDriverPayTotal: React.Dispatch<React.SetStateAction<number | undefined>>;
  driverPayTotal: number | undefined;
};

function OrderPayBreakDown({
  selectedShipment,
  fetchOrderPayBreakDown,
  setDriverPayTotal,
  fetchOrderPayBreakDownLoading,
  driverPayTotal,
  fetchOrderPayBreakDownData,
}: Props) {
  const { courieUser, isEmployee, customClaims } = useContext(AuthContext);
  const { showToast } = useCourieStore();

  const [
    updateDriverPayOverrideMutation,
    { loading: updateDriverPayOverrideMutationLoading },
  ] = useUpdateDriverPayOverrideMutation();

  const [isDriverCompensationOverride, setIsDriverCompensationOverride] =
    useState(false);

  useEffect(() => {
    if (fetchOrderPayBreakDownData?.orderPayBreakDown) {
      setDriverPayTotal(fetchOrderPayBreakDownData.orderPayBreakDown.total);
      const isDriverCompensationOverride =
        fetchOrderPayBreakDownData.orderPayBreakDown.items.some(
          (item) => item.name === "Overridden Compensation"
        );
      setIsDriverCompensationOverride(isDriverCompensationOverride);
    }
  }, [fetchOrderPayBreakDownData]);

  if (fetchOrderPayBreakDownLoading) {
    return <Loading loading={true} className="p-3 h-full overflow-auto" />;
  }

  const onSave = () => {
    if (selectedShipment) {
      const variables = {
        variables: {
          orderId: selectedShipment.order.id,
          driverPayOverride: driverPayTotal,
          updatedBy: isEmployee
            ? "EMPLOYEE"
            : courieUser?.id || customClaims?.courieUserId || undefined,
        },
      };
      updateDriverPayOverrideMutation(variables)
        .then((res) => {
          if (res.data?.updateDriverPayOverride) {
            showToast({
              type: "success",
              message: "Driver pay override updated successfully",
            });
          }
        })
        .catch((e) => {
          showErrorToast(e, showToast);
        });
    }
  };

  const handleUndoOverride = () => {
    if (selectedShipment) {
      const variables = {
        variables: {
          orderId: selectedShipment.order.id,
          driverPayOverride: null,
        },
      };
      updateDriverPayOverrideMutation(variables)
        .then((res) => {
          if (res.data?.updateDriverPayOverride) {
            showToast({
              type: "success",
              message: "Driver pay override removed successfully",
            });
          }
        })
        .catch((e) => {
          showErrorToast(e, showToast);
        });
    }
  };

  if (fetchOrderPayBreakDownData?.orderPayBreakDown) {
    const orderPayBreakDown = fetchOrderPayBreakDownData.orderPayBreakDown;
    return (
      <>
        <div className="shadow-md p-4 pt-2">
          <div className="flex flex-col">
            {orderPayBreakDown.items.map((item, index) => (
              <div key={index}>
                <Label>{parseFloat(item.amount).toFixed(2)}</Label>
                <p className="text-xs -mt-1 text-gray-500">
                  {item.name}
                  {item.description?.includes("%") ? (
                    <>({formatDescription(item.description)})</>
                  ) : (
                    <>({item.description})</>
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col border-t mt-4 pt-2">
            <Label className="font-bold">Total</Label>
            <Loading loading={updateDriverPayOverrideMutationLoading}>
              <DollarInput
                dollar={Number(driverPayTotal) || 0}
                setDollar={(value) => setDriverPayTotal(value)}
                onChange={(event) => {
                  const value = event.target.value;
                  setDriverPayTotal(Number(value));
                }}
              />
            </Loading>
          </div>
          <div className="flex justify-between mt-4 ">
            {isDriverCompensationOverride ? (
              <span
                onClick={() => handleUndoOverride()}
                className="flex truncate items-center text-xs cursor-pointer text-blue-500 hover:underline"
              >
                <ArrowUturnLeftIcon className="h-4 w-4 mr-1" />
                Undo Override
              </span>
            ) : (
              <div></div>
            )}
            <Button
              disabled={
                orderPayBreakDown.total === driverPayTotal ||
                updateDriverPayOverrideMutationLoading
              }
              size="xs"
              onClick={() => onSave()}
              isProcessing={updateDriverPayOverrideMutationLoading}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </>
    );
  }
  return null;
}

function formatDescription(description: string): string {
  const number = parseFloat(description);
  return number % 1 === 0 ? `${number.toFixed(0)}%` : `${number.toFixed(2)}%`;
}

export default OrderPayBreakDown;
