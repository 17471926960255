import {
  EndCustomer,
  ShipmentStatus,
} from "@api/graphql/generated/generated-types";
import { ShipmentsPrimaryFilterType } from "../types";
import {
  currentEpochSeconds,
  minutesAfterCurrentEpochSeconds,
  toDate,
} from "@src/common/lib/DateUtils";
import moment from "moment";

export function makeGetShipmentsVariable(
  courierId: string,
  selectedPrimaryFilter: ShipmentsPrimaryFilterType,
  minDate: Date | undefined,
  maxDate: Date | undefined,
  driverIdsFilter: string[] | undefined,
  selectedCustomer: EndCustomer | undefined
) {
  let lateByFilter: number | undefined = undefined;
  let minClosingTimeFilter: number | undefined = undefined;
  let likelyLateOnlyFilter: boolean | undefined = undefined;
  let isClosedFilter: boolean | undefined = undefined;
  let isAssignedFilter: boolean | undefined = undefined;
  let statusListFilter: ShipmentStatus[] | undefined = [
    ShipmentStatus.Created,
    ShipmentStatus.Assigned,
    ShipmentStatus.Routed,
    ShipmentStatus.Paused,
    ShipmentStatus.InTransit,
  ];
  const currentEpoch = currentEpochSeconds();
  switch (selectedPrimaryFilter) {
    case ShipmentsPrimaryFilterType.ALL:
      statusListFilter = undefined;
      isClosedFilter = undefined;
      break;
    case ShipmentsPrimaryFilterType.ACTIVE:
      isClosedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.UNASSIGNED:
      isAssignedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.ASSIGNED:
      isAssignedFilter = true;
      break;
    case ShipmentsPrimaryFilterType.LATE:
      lateByFilter = currentEpoch;
      isClosedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.LATE_IN_15_MINUTES:
      lateByFilter = minutesAfterCurrentEpochSeconds(15);
      minClosingTimeFilter = currentEpoch;
      isClosedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.LATE_IN_30_MINUTES:
      lateByFilter = minutesAfterCurrentEpochSeconds(30);
      minClosingTimeFilter = currentEpoch;
      isClosedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.LATE_IN_60_MINUTES:
      lateByFilter = minutesAfterCurrentEpochSeconds(60);
      minClosingTimeFilter = currentEpoch;
      isClosedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.LIKELY_LATE:
      likelyLateOnlyFilter = true;
      minClosingTimeFilter = currentEpoch;
      isClosedFilter = false;
      break;
    case ShipmentsPrimaryFilterType.DELIVERED:
      isClosedFilter = false;
      statusListFilter = [ShipmentStatus.Completed];
      break;
    case ShipmentsPrimaryFilterType.CLOSED:
      isClosedFilter = true;
      statusListFilter = undefined;
      break;
  }

  return {
    courierId: courierId,
    lateByFilter: lateByFilter,
    minClosingTimeFilter: minClosingTimeFilter,
    likelyLateOnlyFilter: likelyLateOnlyFilter,
    minExpectedCompletionDate: minDate
      ? moment(minDate).format("YYYY-MM-DD")
      : undefined,
    maxShipmentDate: maxDate ? moment(maxDate).format("YYYY-MM-DD") : undefined,
    isClosedFilter: isClosedFilter,
    statusListFilter: statusListFilter,
    driverIdsFilter: driverIdsFilter,
    isAssignedFilter: isAssignedFilter,
    endCustomerIdsFilter: selectedCustomer ? [selectedCustomer.id] : undefined,
  };
}
