import {
  DriverPayAllocationReplacement,
  Scalars,
  useReplaceAllocationMutation,
} from "@api/graphql/generated/generated-types";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import React, { useEffect, useState } from "react";
import { Label } from "flowbite-react";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { DriverFilterSelector } from "@src/shipments/components/DriverFilterSelector";
import { useCourieStore } from "@src/common/lib/store";
import CourieButton from "../Button/Button";
import PercentInput from "../Input/PercentInput";

export type DriverPayAllocationInput = {
  allocationRate: Scalars["Money"];
  driverId: Scalars["ID"];
};

type Props = {
  driverPayAllocations: DriverPayAllocationInput[] | undefined;
  onConfirm?: () => void;
};

function ReplaceAllocationView({ driverPayAllocations, onConfirm }: Props) {
  const { selectedRow } = useShipmentSelectionContext();
  const { showToast } = useCourieStore();
  const [replaceAllocationMutation, { data, loading, error }] =
    useReplaceAllocationMutation();
  const [allocations, setAllocations] = useState<DriverPayAllocationInput[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (driverPayAllocations) {
      if (
        ["SELECT"].includes((document.activeElement as HTMLElement).tagName)
      ) {
        return;
      }
      const emptyAllocations = allocations.filter(
        (allocation) => allocation.driverId === ""
      );
      if (emptyAllocations.length) {
        return;
      }
      setAllocations(driverPayAllocations);
    }
  }, [driverPayAllocations]);

  const handleDriverChange = (index: number, driverId: Scalars["ID"]) => {
    const updatedAllocations = [...allocations];
    updatedAllocations[index].driverId = driverId;
    setAllocations(updatedAllocations);
  };

  const handleRateChange = (index: number, rate: number) => {
    const updatedAllocations = [...allocations];
    const totalRate = updatedAllocations.reduce(
      (acc, alloc, i) => (i === index ? acc : acc + alloc.allocationRate),
      0
    );
    const rateDecimal = rate / 100;
    if (rate < 0 || rate > 100) {
      setErrorMessage("Allocation rate must be between 0 and 100%");
      return;
    }
    if (totalRate + rateDecimal > 1) {
      setErrorMessage("Total allocation rate cannot exceed 100%");
      return;
    }
    updatedAllocations[index].allocationRate = rateDecimal;
    setAllocations(updatedAllocations);
    setErrorMessage("");
  };

  const handleRemoveAllocation = (index: number) => {
    const updatedAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(updatedAllocations);
  };

  const handleSave = () => {
    if (!selectedRow || !selectedRow.shipment) {
      return;
    }
    const totalRate = allocations.reduce(
      (acc, alloc) => acc + parseFloat(alloc.allocationRate),
      0
    );
    if (totalRate !== 1) {
      setErrorMessage("Total allocation rate must be exactly 100%");
      return;
    }
    const driverPayAllocationInputs = allocations.map(
      ({ driverId, allocationRate }) => ({
        driverId,
        allocationRate,
      })
    );
    const replacement: DriverPayAllocationReplacement = {
      allocations: driverPayAllocationInputs,
      shipmentId: selectedRow.shipment.id,
    };
    replaceAllocationMutation({
      variables: {
        replacement,
      },
    })
      .then(() => {
        showToast({
          message: "Allocations updated successfully",
          type: "success",
        });
        onConfirm && onConfirm();
        errorMessage && setErrorMessage("");
      })
      .catch((error) => {
        const errorMessage = error.message.includes(
          "Sum of allocation rates must be 1.0"
        )
          ? "Total allocation rate must be exactly 100%"
          : "Failed to update allocations";
        showToast({ message: errorMessage, type: "error" });
        setErrorMessage(errorMessage);
      });
  };

  const handleAddAllocation = () => {
    const driverId = selectedRow?.shipment?.driverId;
    if (!driverId) {
      return;
    }
    if (allocations.find((alloc) => alloc.driverId === driverId)) {
      setAllocations([...allocations, { allocationRate: 0, driverId: "" }]);
      return;
    }
    if (allocations.length > 0) {
      setAllocations([...allocations, { allocationRate: 0, driverId: "" }]);
      return;
    }
    setAllocations([...allocations, { allocationRate: 1, driverId }]);
  };

  return (
    <div className="p-1 bg-white">
      {errorMessage && (
        <p className="text-red-600 text-sm mb-2">{errorMessage}</p>
      )}
      {allocations.map((allocation, i) => (
        <div
          className="flex items-center gap-4 mb-2 p-2 border border-gray-300 rounded-lg hover:bg-gray-50 overflow-auto"
          key={i}
        >
          <div className="w-6/12 pt-4">
            <DriverFilterSelector
              driverId={allocation.driverId}
              setDriverId={(driverId) =>
                driverId && handleDriverChange(i, driverId)
              }
              clearable={false}
              showAvatar={false}
            />
          </div>
          <div className="w-6/12 flex flex-col" style={{ minWidth: 140 }}>
            <span className="text-xs">Allocation (%)</span>
            <div className="flex items-center gap-2">
              <PercentInput
                size="mini"
                percent={allocation.allocationRate * 100}
                setPercent={(value) => handleRateChange(i, Number(value))}
                placeholder="Enter allocation rate"
              />
              <TrashIcon
                onClick={() => handleRemoveAllocation(i)}
                className=" h-5 w-5 inline-block cursor-pointer hover:bg-slate-200 rounded-full p-0.5"
              />
            </div>
          </div>
        </div>
      ))}
      <CourieButton
        size={"xs"}
        color={"secondary"}
        onClick={handleAddAllocation}
        className={"cursor-pointer text-blue-500 hover:underline"}
      >
        <PlusCircleIcon className="h-5 w-5 mr-2" />
        Add Allocation
      </CourieButton>

      <div className="flex justify-end mt-2 border-t pt-2">
        <CourieButton size={"xs"} onClick={handleSave} disabled={loading}>
          Save Changes
        </CourieButton>
      </div>
    </div>
  );
}

export default ReplaceAllocationView;
