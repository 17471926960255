import { Badge } from "@src/common/components";
import { BadgeProps } from "flowbite-react";

interface Props extends BadgeProps {
  children: React.ReactNode;
  badgeContent?: number | string | undefined;
}

export function FilterNameWithBadge({
  children,
  badgeContent,
  ...props
}: Props) {
  return (
    <div className="flex items-center gap-1 pt-1">
      <div className={`flex-init truncate ${!badgeContent && "pt-0.5"}`}>
        {children}
      </div>
      {badgeContent && (
        <div className="flex-1">
          <Badge
            size="xs"
            className="flex items-center justify-center w-6 h-6 rounded-full"
            {...props}
          >
            {badgeContent}
          </Badge>
        </div>
      )}
    </div>
  );
}
