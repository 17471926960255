import { Driver, DriverEdge } from "@api/graphql/generated/generated-types";
import { DriverOption } from "../components/DriverFilterSelector";

export function makeDriverOptions(driverEdges: DriverEdge[]): DriverOption[] {
  return driverEdges.map((driverEdge) => ({
    name: `${driverEdge.node?.firstName} ${driverEdge.node?.lastName}`,
    id: driverEdge.node?.id || "",
    url: driverEdge.node?.photoUrl || undefined,
    driver: driverEdge.node as Driver | undefined,
    isActive: driverEdge.node?.isActive || false,
  }));
}
