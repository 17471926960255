import CourieButton from "@src/common/components/Button";
import DollarInput from "@src/common/components/Input/DollarInput";
import { Label } from "flowbite-react";
import React from "react";

function BasePriceOverrideForm({
  basePriceOverrideValue,
  setBasePriceOverrideValue,
  setBasePriceOverrideMode,
}) {
  return (
    <div className="flex flex-col w-full p-2 gap-1 bg-slate-50 rounded-md">
      <Label>Base Price Override</Label>
      <DollarInput
        size="mini"
        dollar={Number(basePriceOverrideValue) || 0}
        setDollar={(value) => setBasePriceOverrideValue(value)}
        onChange={(event) => {
          const value = event.target.value;
          setBasePriceOverrideValue(Number(value));
        }}
      />

      <div className="flex justify-end">
        <CourieButton
          size={"xs"}
          color={"secondary"}
          onClick={() => {
            setBasePriceOverrideMode(false);
            setBasePriceOverrideValue(0);
          }}
        >
          Cancel
        </CourieButton>
      </div>
    </div>
  );
}

export default BasePriceOverrideForm;
